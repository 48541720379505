import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

export const SNACKBAR_TIME = {
  SUCCESS: 3000,
  INFO: 4000,
  ERROR: 5000,
};
export const DEFAULT_SNACKBAR = { show: false, autoHideDuration: 2000, severity: 'success', message: '' };

const clients = ['sertuva'];
export const WHITE_LABEL = clients.find(client => window.location.host.includes(client)) || 'app';

export const IS_STAGE = window.location.host.startsWith('stage.');

export const ERRORS = {
  DEVICE_NOT_FOUND: 'NotFoundError: Requested device not found',
  PERMISSION_DENIED: 'NotAllowedError: Permission denied',
  PERMISSION_DISMISSED: 'NotAllowedError: Permission dismissed',
};

export const updateManifestAndIcons = () => {
  const manifest = document.querySelector('link[rel="manifest"]');
  if (manifest) {
    manifest.href = `${ process.env.PUBLIC_URL }/${ WHITE_LABEL }/manifest.json`;
  }

  const icon = document.querySelector('link[rel="icon"]');
  if (icon) {
    icon.href = `${ process.env.PUBLIC_URL }/${ WHITE_LABEL }/favicon.ico`;
  }

  const appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]');
  if (appleTouchIcon) {
    appleTouchIcon.href = `${ process.env.PUBLIC_URL }/${ WHITE_LABEL }/logo192.png`;
  }
};

/**
 * Remove time zone from date string
 * @param {string} date - The date that needs to be updated.
 */
export const transformDate = date => date.split('T')[0].replace(/-/g, '/');

/**
 * Remove time zone from date string and create a more readable date
 * @param {string} date - The date that needs to be updated.
 * @param {function} t - The translation function.
 */
export const formatDate = (date, t) => {
  let fullDate = new Date( transformDate(date) );
  return `${ t(`global.months.${ fullDate.getMonth() }`) } ${ fullDate.getDate() }, ${ fullDate.getFullYear() }`;
};

/**
 * Update time string and create a more readable time
 * @param {string} time - The time that needs to be updated.
 */
export const formatTime = (time) => {
  const formatTime = time.split(':');
  let hours = formatTime[0];
  let minutes = formatTime[1];
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  return `${ hours }:${ minutes } ${ ampm }`;
};

/**
 * Remove time zone from date string and create a more readable date
 * @param {string} date - The date that needs to be updated.
 * @param {string} time - The time that needs to be updated.
 * @param {function} t - The translation function.
 */
export const formatDateTime = (date, time, t) => {
  let fullDate = new Date( transformDate(date) );
  const fullTime = time.split(':');
  let hours = fullTime[0];
  let minutes = fullTime[1];
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  return `${ t(`global.months.${ fullDate.getMonth() }`) } ${ fullDate.getDate() }, ${ fullDate.getFullYear() }, ${ hours }:${ minutes } ${ ampm }`;
};

/**
 * Sort time/string element.
 * @param a, object
 * @param b, object
 */
 export const sortByPlaceTime = (a, b) => a.place?.arrivalTime && b.place?.arrivalTime ? a.place.arrivalTime.localeCompare(b.place.arrivalTime) : 1;

 /**
 * Sort time/string element.
 * @param a, object
 * @param b, object
 */
 export const sortByDisplayOrder = (a, b) => a.reservation?.displayOrder - b.reservation?.displayOrder;

 export const identifyLogRocketUser = (tripId, routeId, vehicleId, departureDate) => {
  if ( localStorage.getItem('id') ) {
    LogRocket.identify(localStorage.getItem('id'), {
      name: localStorage.getItem('name'),
      email: localStorage.getItem('email'),
      username: localStorage.getItem('username'),
      token: localStorage.getItem('token'),
      countryId: localStorage.getItem('countryId'),
      ...(tripId && { tripId }),
      ...(routeId && { routeId }),
      ...(vehicleId && { vehicleId }),
      ...(departureDate && { departureDate }),
    });
  }
 }

/**
 * Handle endpoint errors
 * @param {Object} state - The current state that you want to check.
 * @param {Object} props - The props of the page.
 * @param {function} setSnackbar - The action to call the snackbar component.
 * @param {function} t - The translation function.
 * @param {string} entity - Optional: The entity of the current action to be used on some messages
 */
export const handleEndpointErrors = (state, props, setSnackbar, t, entity) => {
  if (state.error.status === 401) {
    props.logout(true, true);
  } else if (state.error.status === 420) {
    if (entity) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.420withEntity', { entity }) });
    } else {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.420withoutEntity') });
    }
  } else if (state.error.status === 403) {
    if (state.error.data?.errors?.length && state.error.data.errors[0].includes('not allowed to make a reservation')) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.403') });
    }
  } else if (state.error.status === 404) {
    if (state.error.data?.errors?.length && state.error.data.errors[0] === 'Reservation not found') {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.deletedReservation') });
    } else if (entity) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.404withEntity', { entity }) });
    } else {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.404withoutEntity') });
    }
  } else if (state.error?.data?.errors?.length && state.error.data.errors[0] === 'User is inactive') {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.inactiveUser') });
  } else if (state.error.status === 423) {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.423', { entity }) });
  } else if (state.error.status === 413 && state.error?.data?.errors?.length && state.error.data.errors[0].includes('seats')) {
    const client = state.error.data.clientName;
    if (client) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.413withEntity', { client }) })
    } else {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.413withoutEntity') })
    }
  } else {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.default') });
  }
}

/**
 * Log Sentry endpoint error
 * @param {Object} error - Error that occurred.
 */
export const logSentryEndpointError = error => {
  if (error.response && ![400, 401, 404, 413, 420, 423].includes(error.response.status)) {
    Sentry.withScope(function(scope) {
      scope.setTag('request', `${ error.response.config.url }`);
      scope.setTag('method', `${ error.config.method }`);
      Sentry.setContext('Request Information', {
        'URL': error.response.config.url,
        'Method': error.config.method,
        'Status': error.request.status,
        'Status Text': error.request.statusText,
        'Data': error.config.data,
        'Error': error.request.response,
      });
      Sentry.captureException(error);
    });
  }
}
